<template>
  <div class="container">
    <nav-bar />
    <div class="header">
      <div class="title">忘记密码</div>
      <div class="login-form">
        <phone-input :needCode="true" />
        <code-input :disCode.sync="disCode" :action="2" />
        <pwd-input />
        <Submit ref="submit" :disBtn="$checkBtn(form)" @submit="toReset()" btnStr="完成" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/navbar";
import PhoneInput from "@/components/phoneInput";
import CodeInput from "@/components/codeInput";
import PwdInput from "@/components/pwdInput";
import Submit from "@/components/submit";
import { resetPwd } from "@/api/login";
import { Toast } from "vant";
import { startTrackApp } from "@/tongji";
export default {
  name: "forgot",
  components: {
    NavBar,
    PhoneInput,
    CodeInput,
    PwdInput,
    Submit,
  },
  data() {
    return {
      form: {
        mobile: "",
        password: "",
        code: "",
      },
      disCode: true,
    };
  },
  methods: {
    toReset() {
      let _this = this;
      let { mobile, code, password: new_password } = _this.form;
      let obj = {
        mobile_info: {
          mobile,
          code,
        },
        new_password,
      };
      resetPwd(obj)
        .then(() => {
          Toast("密码已重置成功!");
          setTimeout(() => {
            _this.$router.push("/pwd");
          }, 1000);
        })
        .catch(() => {
          _this.$refs.submit.loading = false;
        });
    },
  },
  mounted() {
    startTrackApp("忘记密码");
  },
};
</script>
